import { createContext, useContext } from 'react';

type menu = {
    list: []; // 菜单列表，用于菜单管理
    tree: []; // trre用于菜单
    pre: {}; // 用于按钮权限处理
};

type user = {
    email: string;
};

type ContextType = {
    menu: menu; // 导航列表tree
    user: user; // 用户数据
    coin_list: [];
    contract_list: []; // 合约币对
    dispatchEvent: (type: string, data?: any) => void;
};

export const GlobalContext = createContext<ContextType | null>(null);

export const useGlobalState = () => {
    const context = useContext(GlobalContext);

    if (context === null) {
        throw new Error('You must add a <ContextProvider> into the React tree');
    }

    return context;
};
