import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import '@/style/index.less';
import '@/style/dark.less';
import RouteMap from '@/router';
import '@/utils/init_config';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GlobalProvider } from './hooks/GlobalProvider';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
import 'moment/locale/zh-tw';
import 'moment/locale/id';
import 'moment/locale/vi';
import '@/utils/lang/i18n';
// import zh_CN from 'antd/es/locale/zh_CN';
// import zh_TW from 'antd/es/locale/zh_TW';
// import en_US from 'antd/es/locale/en_US';
// import id_ID from 'antd/es/locale/id_ID';
// import ko_KR from 'antd/es/locale/ko_KR';
// import vi_VN from 'antd/es/locale/vi_VN';
// import i18n from '@/utils/lang/i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ConfigProvider>
        <GlobalProvider>
            <BrowserRouter>
                <RouteMap />
            </BrowserRouter>
        </GlobalProvider>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
