import { useState, useCallback } from 'react';
import { GlobalContext } from './GlobalContext';
import api from '@/utils/axios/user';
import futureApi from '@/utils/axios/future';
// import system_api from '@/utils/axios/system';
// import future_api from '@/utils/axios/future';
// import { addSkin } from '@/utils/common';
import lighter from '@/style/dark.less';
import { localStorage } from '@/utils/common';
import i18n from '@/utils/lang/i18n'; // 引入自己建的文件i18n.js

// 菜单列表配置
const menuDataHandle = (datas: Array<any>): any => {
    let arr: Array<any> = [];
    let permission: any = {};
    datas.forEach((item: any) => {
        arr.push({
            id: item.id,
            parentId: item.parentId,
            path: item.path,
            title: item.title,
            permission: item.permission,
        });

        if (item.permission) {
            permission[item.permission] = true;
        }

        if (item.children.length) {
            const arrs = menuDataHandle(item.children).list;
            arr = arr.concat(arrs);
            const obj = menuDataHandle(item.children).pre;
            permission = { ...permission, ...obj };
        }
    });
    return {
        list: arr,
        pre: permission,
    };
};

export const GlobalProvider: React.FC<any> = ({ children }) => {
    const [menu, setMenu] = useState<any>({
        list: [], // 菜单列表，用于菜单管理
        tree: [], // trre用于菜单
        pre: {}, // 用于btn权限处理
    });
    const [user, setUser] = useState<any>({});
    const [coin_list, setCoinList] = useState<any>([]);
    const [contract_list, setContract] = useState<any>([]);
    const dispatchEvent = useCallback(async (type: string, data?: any) => {
        switch (type) {
            case 'SET_CONTRACT': // 合约币对列表获取
                const contract: any = await futureApi.contract_future({});
                const newArray: any[] = [];
                Object.keys(contract.result).forEach((key) => {
                    newArray.push({ id: contract.result[key], name: key });
                });
                setContract(newArray);
                return;
            case 'SET_USER': //
                const user: any = await api.user_info({});
                setUser(user);
                return;
            case 'AREA':
                const area: any = await api.is_china_mainland({});
                const lang = localStorage('get', 'Lang');
                if (area) {
                    if (area.isChina === false && !lang) {
                        localStorage('set', 'Lang', 'zh-TW');
                        i18n.changeLanguage('zh-TW');
                    } else if (area.isChina && !lang) {
                        localStorage('set', 'Lang', 'zh-CN');
                        i18n.changeLanguage('zh-CN');
                    }
                }
                return;
        }
    }, []);

    return (
        <GlobalContext.Provider value={{ menu, user, coin_list, contract_list, dispatchEvent }}>
            {children}
        </GlobalContext.Provider>
    );
};
