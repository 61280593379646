import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, Method, AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { getSign, getPostParams } from '@/utils/common';
import { COOKIES } from '@/utils/enum/storage';
import { localStorage } from '@/utils/common';

interface RequestData<T = any> {
    url?: string;
    type: Method | string;
    data?: T;
    content_type?: number;
    token_type?: number;
}

// Content-Type枚举
enum content_type {
    'application/json',
    'application/x-www-form-urlencoded',
    'multipart/form-data',
}

const axiosSendHandle = (_request: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        axios(_request)
            .then((res: AxiosResponse) => {
                const json = res.data;
                if (json.code === 0) {
                    resolve(json.data || 1);
                } else {
                    reject(json);
                }
            })
            .catch((e: AxiosError) => {
                if (e.response && e.response.status === 401) {
                    Cookies.set(COOKIES.TOKEN, '');
                    (React as any).$navigate('/login');
                } else {
                    reject(e);
                }
            });
    });
};

/**
 * axios请求中间件处理
 * @param data 接口数据
 * {
 *      url: 接口地址
 *      type: 请求方法
 *      data: 接口请求参数
 *      content_type：默认为json格式请求，1是form表单请求，2是form-data请求
 *      token_type：默认不传token，1是header里传token，2是直接参数里传token给服务器
 * }
 * @returns {Promise} 返回请求结果Promise格式
 */
export const axiosRequestMethod = (data: RequestData) => {
    const params = data;
    const _request: any = {
        url: params.url,
        method: params.type,
        headers: {
            'Content-Type': content_type[params.content_type || 0],
            'ext-data': JSON.stringify({
                ts: Math.round(new Date().getTime()), //时间戳
                lang: localStorage('get', 'Lang') || 'zh-CN',
            }),
        },
    };

    // token类型判断
    if (data.token_type === 1) {
        _request['headers']['Authorization'] = `bearer ${Cookies.get(COOKIES.TOKEN)}`;
    } else if (data.token_type === 2) {
        params['data']['token'] = Cookies.get(COOKIES.TOKEN);
    }

    // Content-Type类型为'multipart/form-data'判断
    if (params.content_type === 2) {
        _request['data'] = params.data;
        return axiosSendHandle(_request);
    }

    // 全局参数配置
    // params['data']['platform'] = '';
    // params['data']['system'] = '';
    // params['data']['language'] = 'zh';

    // 签名
    params['data']['sign'] = getSign(params['data']);

    // 不同的请求方式赋值
    if (params.type === 'get') {
        _request['params'] = params['data'];
    } else {
        if (typeof params.content_type !== 'undefined' && params.content_type === 1) {
            _request['data'] = getPostParams(params['data']);
        } else {
            _request['data'] = params['data'];
        }
    }

    return axiosSendHandle(_request);
};
