import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './resources';
import { localStorage } from '@/utils/common';

// let Lange = window.localStorage.getItem('Lang');
i18n
    // 将 i18n 实例传递给 react-i18next
    .use(initReactI18next)
    // 初始化 i18next
    // 所有配置选项: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: localStorage('get', 'Lang') || 'zh-CN',
        lng: navigator.language,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

(window as any).glb = {
    ...(window as any).glb,
    $t: i18n.t,
};

export default i18n;
