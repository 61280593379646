import zh from './message/cn.json';
import tw from './message/tw.json';
import en from './message/en.json';
import id from './message/id.json';
import ko from './message/ko.json';
import vi from './message/vi.json';

export const resources = {
    'zh-CN': {
        translation: zh,
    },
    'zh-TW': {
        translation: tw,
    },
    'en-US': {
        translation: en,
    },
    'ko-KR': {
        translation: ko,
    },
    'id-ID': {
        translation: id,
    },
    'vi-VN': {
        translation: vi,
    },
};
