import axios from 'axios';

// axios初始化
const apiUrl: string = process.env.REACT_APP_API_URL || '';
// axios.defaults.withCredentials = true;
// if (process.env.NODE_ENV !== 'development') {
axios.defaults.baseURL = apiUrl;
// }
axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
