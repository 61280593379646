import React from 'react';
import {
    useRoutes,
    Navigate,
    useNavigate,
    useLocation,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import ComponentWrap from './config';

const Router = () => {
    (React as any).$navigate = useNavigate();
    (React as any).$location = useLocation();

    const RouteMap = useRoutes([
        {
            path: '/login',
            element: <ComponentWrap name="login" />,
        },
        // {
        //     path: '/register',
        //     element: <ComponentWrap name="register" />,
        // },
        // {
        //     path: '/forget',
        //     element: <ComponentWrap name="forget" />,
        // },
        // {
        //     path: '/unbind',
        //     element: <ComponentWrap name="unbind" />,
        // },
        // {
        //     path: '/google-bing',
        //     element: <ComponentWrap name="google" />,
        // },
        {
            path: '/',
            element: <ComponentWrap name="main" isLogin={true} />,
            children: [
                // 总览
                {
                    path: '',
                    element: <ComponentWrap name="home" />,
                },
                {
                    path: 'partner',
                    element: <ComponentWrap name="routeBody" isLogin={true} />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="/partner/list" />,
                        },
                        {
                            path: 'list',
                            element: <ComponentWrap name="list" />,
                        },
                        {
                            path: 'statement',
                            element: <ComponentWrap name="statement" />,
                        },
                    ],
                },
                {
                    path: 'user',
                    element: <ComponentWrap name="routeBody" isLogin={true} />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="/user/list" />,
                        },
                        {
                            path: 'list',
                            element: <ComponentWrap name="userList" />,
                        },
                    ],
                },
                {
                    path: 'finance',
                    element: <ComponentWrap name="routeBody" isLogin={true} />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="/finance/transfer" />,
                        },
                        {
                            path: 'assets',
                            element: <ComponentWrap name="assets" />,
                        },
                        {
                            path: 'transfer',
                            element: <ComponentWrap name="transfer" />,
                        },
                    ],
                },
                {
                    path: 'airdrop',
                    element: <ComponentWrap name="routeBody" isLogin={true} />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="/airdrop/details" />,
                        },
                        {
                            path: 'details',
                            element: <ComponentWrap name="airdrop_details" />,
                        },
                        {
                            path: 'summary',
                            element: <ComponentWrap name="airdrop_summary" />,
                        },
                    ],
                },
                {
                    path: 'contract',
                    element: <ComponentWrap name="routeBody" isLogin={true} />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="/contract/position" />,
                        },
                        {
                            path: 'position',
                            element: <ComponentWrap name="position" />,
                        },
                        {
                            path: 'settlement',
                            element: <ComponentWrap name="settlement" />,
                        },
                    ],
                },

                // {
                //     // 系统管理
                //     path: 'system',
                //     element: <ComponentWrap name="routeBody" isLogin={true} />,
                //     children: [
                //         {
                //             path: '',
                //             element: <Navigate to="/system/role" />,
                //         },
                //         {
                //             path: 'role',
                //             element: <ComponentWrap name="role" />,
                //         },
                //         {
                //             path: 'user',
                //             element: <ComponentWrap name="user_system" />,
                //         },
                //         {
                //             path: 'menu',
                //             element: <ComponentWrap name="menu" />,
                //         },
                //         {
                //             path: 'uid',
                //             element: <ComponentWrap name="uid_transfer" />,
                //         },
                //     ],
                // },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/" />,
        },
    ]);

    return RouteMap;
};

export default Router;
