import { axiosRequestMethod } from './axios_config';

export default {
    contract_future: (data: object) =>
        axiosRequestMethod({
            url: 'api/agent/v2/contract_future', // 合约币对设置
            type: 'get',
            data: data,
            token_type: 1,
        }),

    position: (data: object) =>
        axiosRequestMethod({
            url: 'api/agent/v2/position/page', // 持仓查询
            type: 'post',
            data: data,
            token_type: 1,
        }),
    user_trading: (data: object) =>
        axiosRequestMethod({
            url: 'api/agent/v2/user_trading_daily/page', // 成交汇总
            type: 'post',
            data: data,
            token_type: 1,
        }),
};
