import { axiosRequestMethod } from './axios_config';

export default {
    register: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/register', // 注册
            type: 'post',
            data: data,
        }),

    generate_google_key: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/generate_google_key ', // 谷歌key
            type: 'post',
            data: data,
        }),
    bind_google_authenticator: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/bind_google_authenticator', // 开启谷歌
            type: 'post',
            data: data,
        }),

    newlogin: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/login', // 注册
            type: 'post',
            data: data,
        }),
    logout: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/logout', // 退出登录
            type: 'post',
            data: data,
            token_type: 1,
        }),

    user_page: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/user/page', // 用户列表
            type: 'post',
            data: data,
            token_type: 1,
        }),
    sed_code: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/send_email_code', // 发送邮箱验证码
            type: 'post',
            data: data,
            token_type: 1,
        }),
    user_info: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/user_info', // 个人信息
            type: 'get',
            data: data,
            token_type: 1,
        }),
    agent_modify_password: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/agent_modify_password', // 修改密码
            type: 'post',
            data: data,
            token_type: 1,
        }),
    get_google_key: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/get_google_key', // 获取新谷歌key
            type: 'get',
            data: data,
            token_type: 1,
        }),
    rebind_google: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/rebind_google ', // 谷歌绑定跟新
            type: 'post',
            data: data,
            token_type: 1,
        }),
    rebate_ratio: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/user/rebate_ratio', // 修改返佣
            type: 'post',
            data: data,
            token_type: 1,
        }),
    is_china_mainland: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/is_china_mainland', // 判断地区
            type: 'get',
            data: data,
        }),
    agent_rebate_ratio: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/agent_rebate_ratio', // 获取自己返佣比例
            type: 'post',
            data: data,
            token_type: 1,
        }),
    forget: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/agent_reset_pass', // 重置密码
            type: 'post',
            data: data,
            // token_type: 1,
        }),
    validate: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/agent_reset_pass_validate', // 验证码校验
            type: 'post',
            data: data,
            // token_type: 1,
        }),
    unbind: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v1/agent_un_bind_google_apply', // 解绑申请
            type: 'post',
            data: data,
            // token_type: 1,
        }),
    agent_update_user: (data: object) =>
        axiosRequestMethod({
            url: '/api/agent/v2/agent_update_user', // 添加备注
            type: 'post',
            data: data,
            token_type: 1,
        }),
};
