// cookie
export const COOKIES = {
    TOKEN: 'AGENTTOKEN', // 登录token
    PASSWORD: 'PASSWORD', // 密码
    AGENTNAME: 'AGENTNAME', // 账号
};

// localstorage
export const LOCAL = {
    LOG_NAME: 'log_name', // 登录账号记录
};
